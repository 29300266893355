<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    :hide-cancel="buscaClientes.show"
    @submit="onSubmit"
    routeback="/fornecedor/comercial/cupom-desconto"
  >
    <div v-show="!buscaClientes.show">
      <Grid>
        <FormField
          label="Código do cupom"
          v-model="form.codigoCupom"
          maxLength="15"
          :validation="$v.form.codigoCupom"
        />
        <FormSelect
          label="Tipo"
          v-model="form.tipoCupom"
          :options="optionsTipoCupom"
          :validation="$v.form.tipoCupom"
          :validarCampo="true"
          @change="cleanDesconto"
        />
      </Grid>
      <FormField
        v-if="form.valorDesconto"
        label="Desconto"
        v-model="form.valorDesconto"
        :validation="$v.form.valorDesconto"
        :money="form.tipoCupom === '%' ? decimalMask : moneyMask"
      />
      <FormField
        v-if="!form.valorDesconto"
        label="Desconto"
        v-model="form.valorDesconto"
        :validation="$v.form.valorDesconto"
      />
      <div class="block-multi-clientes">
        <FormCheckbox
          class="check-box"
          label="Multiplos clientes"
          v-model="multiplosClientes"
        />
        <FormButton
          v-if="multiplosClientes"
          text="Busca avançada"
          @click="buscaClientes.show = true"
        />
      </div>
      <small v-if="multiplosClientes && !buscaClientes.confirm">
        Utilize esse formato para criar uma cópia desse cupom para vários de
        clientes de uma só vez. Adicione um cliente por linha (cpf OU e-mail).
        Todos os clientes devem estar cadastrados no e-commerce para que o
        processo crie os cupons.</small
      >
      <div v-if="buscaClientes.confirm" class="block-multi-clientes">
        <small class="check-box">
          Os filtros da busca serão utilizados para selecionar os clientes.
        </small>
        <FormButton
          text="Limpar busca avançada"
          @click="onBuscaClienteLimpar"
        />
      </div>

      <FormField
        label="Cliente"
        v-model="form.clientes"
        v-if="!multiplosClientes"
      />
      <FormText
        label="Clientes"
        v-model="form.clientes"
        :rows="5"
        v-if="multiplosClientes && !buscaClientes.confirm"
      />

      <FormSelect
        label="Segmento do cliente"
        :options="segments"
        :clearable="true"
        v-model="form.idsegmentocliente"
      />
      <FormAutocomplete
        label="Categoria"
        placeholder="Pesquise aqui a categoria desejada"
        chips
        :clearable="true"
        :options="setoresFolha"
        v-model="form.setor.idsetor"
        @deletedContent="onDeletedContent"
      />
      <Grid v-show="form.setor.idsetor">
        <FormField
          label="Valor mínimo de pedidos da categoria"
          v-model="form.setor.valorMinPedido"
          :money="form.setor.valorMinPedido === null ? null : moneyMask"
          hint="Valor mínimo da categoria em que um cupom pode ser usado"
        />
        <FormField
          label="Valor máximo de pedidos da categoria"
          v-model="form.setor.valorMaxPedido"
          :money="form.setor.valorMaxPedido === null ? null : moneyMask"
          hint="Valor máximo da categoria em que um cupom pode ser usado"
        />
      </Grid>
      <Grid>
        <FormDatePicker
          v-model="form.dataInicio"
          :validation="$v.form.dataInicio"
          label="Data inicial"
        />
        <FormDatePicker
          v-model="form.dataFim"
          :validation="$v.form.dataFim"
          label="Data fim"
        />
        <FormField
          label="Quantidade de usos permitido"
          v-model="form.qtdMaxUso"
          :money="form.qtdMaxUso === null ? null : numberMask"
        />
        <FormField
          label="Quantidade de usos por cliente"
          v-model="form.qtdMaxPorCliente"
          :validation="$v.form.qtdMaxPorCliente"
          :money="numberMask"
        />
        <FormField
          label="Valor mínimo do pedido"
          v-model="form.valorMinPedido"
          :money="form.valorMinPedido === null ? null : moneyMask"
          hint="Valor mínimo do carrinho em que um cupom pode ser usado"
        />
        <FormField
          label="Valor máximo do pedido"
          v-model="form.valorMaxPedido"
          :money="form.valorMaxPedido === null ? null : moneyMask"
          hint="Valor máximo do carrinho em que um cupom pode ser usado"
        />
      </Grid>
      <FormText label="Observações" v-model="form.observacao" :rows="10" />

      <FormCheckbox
        label="Não utilizar com politica de desconto"
        v-model="form.naoUsarComPolitica"
      />
      <FormCheckbox
        v-if="form.setor.idsetor"
        label="Habilitar visualização de desconto em itens"
        v-model="form.habilitarVisualizacaoDesconto"
      />
      <LineDivider><h4>Canais de venda onde o cupom é válido</h4></LineDivider>
      <Grid grid-template-columns="repeat(3, 1fr)">
        <FormCheckbox label="Todos" v-model="useAllDevices" />
        <FormCheckbox label="Aplicativo" val="APP" v-model="form.devices" />
        <FormCheckbox label="Desktop" val="WEB" v-model="form.devices" />
        <FormCheckbox label="Venda+" val="MANUAL" v-model="form.devices" />
      </Grid>
    </div>
    <CupomDescontoBuscaClientes
      :busca-clientes="buscaClientes"
      :segments="segments"
      v-if="buscaClientes.show"
      @confirm="onBuscaClientesConfirm"
      @close="onBuscaClienteCancel"
    />

    <template v-if="!buscaClientes.show" v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import FormCheckbox from '@/components/form/FormCheckbox'
import FormDatePicker from '@/components/form/FormDatePicker'
import FormText from '@/components/form/FormText'
import FormSelect from '@/components/form/FormSelect'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import CupomDescontoBuscaClientes from './CupomDescontoBuscaClientes'
import SidePopup from '@/components/SidePopup'
import FormAutocomplete from '@/components/form/FormAutocomplete'
import { CUPOM_DESCONTO_CREATE } from '@/store/actions/cupom'
import { SEGMENTO_CLIENTE_LIST } from '@/store/actions/segmento'
import { required } from 'vuelidate/lib/validators'
import mixinUtils from '@/mixins/utils'
import LineDivider from '@/components/LineDivider'
import Grid from '@/components/Grid'
import { mapGetters } from 'vuex'
import * as moment from 'moment-timezone'
import ApiWeb from '../../../service/api-web'

export default {
  name: 'CupomDescontoForm',
  mixins: [mixinUtils],
  components: {
    SidePopup,
    FormSelect,
    FormField,
    FormText,
    FormAutocomplete,
    LineDivider,
    Grid,
    FormDatePicker,
    FormCheckbox,
    FormButton,
    CupomDescontoBuscaClientes
  },
  validations: {
    form: {
      codigoCupom: {
        required,
        alphanumeric(cod) {
          return cod && cod.length !== cod.replace(/[^a-zA-Z0-9]/g, '').length
            ? false
            : true
        }
      },
      tipoCupom: { required },
      valorDesconto: {
        required,
        numeric(val) {
          return val && val.length !== val.replace(/[^0-9,]/g, '').length
            ? false
            : true
        }
      },
      dataInicio: { required },
      dataFim: { required }
    }
  },
  data: () => ({
    tiposCupom: ['%', 'R$'],
    form: {
      clientes: '',
      codigoCupom: '',
      valorDesconto: '',
      dataInicio: '',
      dataFim: '',
      observacao: '',
      tipoCupom: 'TODOS',
      qtdMaxUso: null,
      qtdMaxPorCliente: 1,
      naoUsarComPolitica: false,
      devices: ['TODOS', 'APP', 'WEB', 'MANUAL'],
      setor: { idsetor: null, valorMaxPedido: null, valorMinPedido: null },
      idsegmentocliente: null,
      valorMaxPedido: null,
      valorMinPedido: null,
      habilitarVisualizacaoDesconto: false
    },
    useAllDevices: true,
    TIPO_CUPOM_VALUE: {
      '%': '%',
      R$: '$',
      $: 'R$'
    },
    decimalMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 2,
      masked: false
    },
    numberMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 0,
      masked: false
    },
    moneyMask: {
      decimal: ',',
      thousands: '',
      prefix: '',
      suffix: '',
      default: '',
      precision: 3,
      masked: false
    },
    enableEditLimit: false,
    multiplosClientes: false,
    buscaClientes: null,
    dadosFornecedor: null
  }),
  watch: {
    multiplosClientes(val) {
      if (!val) {
        this.buscaClientes.confirm = false
      }
    },
    useAllDevices(val) {
      this.form.devices = val ? ['APP', 'WEB', 'MANUAL'] : []
    }
  },
  created() {
    this.buscaClientes = this.getCleanBuscaCliente()
    this.getSegments()
    this.getFornecedor()
  },
  computed: {
    ...mapGetters([
      'getFornecedorTag',
      'getFornecedorId',
      'getSetoresFolhas',
      'getIdListSegmentos'
    ]),
    optionsTipoCupom() {
      return this.tiposCupom.map(t => {
        return { text: t, value: t }
      })
    },
    title() {
      return !this.buscaClientes.show
        ? 'Novo cupom'
        : 'Busca avançada de clientes'
    },
    setoresFolha() {
      return this.getSetoresFolhas.reduce((folhas, folha) => {
        if (this.getIdListSegmentos.includes(folha.idsegmento)) {
          folhas.push({
            text: `${folha.segmento}/${folha.rotulo}`,
            value: folha.id
          })
        }
        return folhas
      }, [])
    },
    segments() {
      return (this.$store.getters.getSegmentos || []).map(s =>
        this.getDataToSelect(s, 'nome', 'idsegmentocliente')
      )
    }
  },
  methods: {
    getFornecedor() {
      ApiWeb.v1.fornecedor
        .obtemDadosFornecedor(this.getFornecedorId)
        .then(resp => {
          this.dadosFornecedor = resp.data
        })
    },
    getSegments() {
      this.$store.dispatch(SEGMENTO_CLIENTE_LIST, {
        idfornecedor: this.getFornecedorId
      })
    },
    getDataToSelect(item, labelField, valueField) {
      return item
        ? {
            text: item[labelField],
            value: item[valueField]
          }
        : null
    },
    cleanDesconto() {
      this.form.valorDesconto = ''
    },
    getCleanBuscaCliente() {
      return {
        show: false,
        confirm: false,
        form: {
          dataCadastroInicio: '',
          dataCadastroFim: '',
          dataCompraInicio: '',
          dataCompraFim: '',
          dataAcessoInicio: '',
          dataAcessoFim: '',
          dataNascimentoInicio: '',
          dataNascimentoFim: '',
          segmentos: '',
          fornecedores: [this.getFornecedorId],
          carrinhoAberto: false,
          cadastroAprovado: false
        }
      }
    },
    prepareData() {
      if (!this.form.dataFim) this.form.dataFim = '2118-01-01'
      var start = moment(this.form.dataInicio).tz(
        this.dadosFornecedor.local_timezone
      )
      var end = moment(this.form.dataFim).tz(
        this.dadosFornecedor.local_timezone
      )
      start.startOf('day')
      end.endOf('day')

      let desconto = this.form.valorDesconto
        ? this.form.valorDesconto.replace(/[%R$]/g, '')
        : this.form.valorDesconto

      desconto = this.prepareNumberDecimal(desconto)
      let clientes = this.form.clientes
      if (this.multiplosClientes) {
        clientes = this.form.clientes.split(/[;,/ \n]/)
        clientes = clientes
          .map(item => `${item}`.toLowerCase().trim())
          .filter(item => item)
      } else {
        clientes = [`${this.form.clientes}`.toLowerCase().trim()]
      }

      if (this.buscaClientes.confirm) {
        this.buscaClientes.form.dataAcessoInicio = this.queryDate(
          true,
          this.buscaClientes.form.dataAcessoInicio
        )
        this.buscaClientes.form.dataAcessoFim = this.queryDate(
          false,
          this.buscaClientes.form.dataAcessoFim
        )
        this.buscaClientes.form.dataCadastroInicio = this.queryDate(
          true,
          this.buscaClientes.form.dataCadastroInicio
        )
        this.buscaClientes.form.dataCadastroFim = this.queryDate(
          false,
          this.buscaClientes.form.dataCadastroFim
        )
        this.buscaClientes.form.dataCompraInicio = this.queryDate(
          true,
          this.buscaClientes.form.dataCompraInicio
        )
        this.buscaClientes.form.dataCompraFim = this.queryDate(
          false,
          this.buscaClientes.form.dataCompraFim
        )
        this.buscaClientes.form.dataNascimentoInicio = this.queryDate(
          true,
          this.buscaClientes.form.dataNascimentoInicio
        )
        this.buscaClientes.form.dataNascimentoFim = this.queryDate(
          false,
          this.buscaClientes.form.dataNascimentoFim
        )
      }

      return {
        ...this.form,
        codigocupom: this.form.codigoCupom,
        clientes: !this.buscaClientes.confirm ? clientes : [],
        desconto,
        datainicio: start.format('YYYY-MM-DD HH:mm:ss ZZ'),
        datafim: end.format('YYYY-MM-DD HH:mm:ss ZZ'),
        tipocupom: this.TIPO_CUPOM_VALUE[this.form.tipoCupom],
        valorMinPedido: this.prepareNumberDecimal(this.form.valorMinPedido),
        valorMaxPedido: this.prepareNumberDecimal(this.form.valorMaxPedido),
        valorMinPedidoCategoria: this.prepareNumberDecimal(
          this.form.setor.valorMinPedido
        ),
        valorMaxPedidoCategoria: this.prepareNumberDecimal(
          this.form.setor.valorMaxPedido
        ),
        idsetor: this.form.setor.idsetor,
        buscaClientes: this.buscaClientes.confirm
          ? this.buscaClientes.form
          : null
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (this.form.tipoCupom === 'TODOS') {
        return this.$vueOnToast.pop('warning', 'Informe o tipo do cupom.')
      }

      if (
        !(
          this.form.valorMinPedido === null || this.form.valorMaxPedido === null
        )
      ) {
        if (
          parseFloat(this.form.valorMinPedido) >=
          parseFloat(this.form.valorMaxPedido)
        ) {
          return this.$vueOnToast.pop(
            'warning',
            'O valor mínimo do pedido não pode ser maior ou igual ao valor máximo do pedido.'
          )
        }
      }

      if (
        !(
          this.form.setor.valorMinPedido === null ||
          this.form.setor.valorMaxPedido === null
        )
      ) {
        if (
          parseFloat(this.form.setor.valorMinPedido) >=
          parseFloat(this.form.setor.valorMaxPedido)
        ) {
          return this.$vueOnToast.pop(
            'warning',
            'O valor mínimo da categoria não pode ser maior ou igual ao valor máximo da categoria.'
          )
        }
      }

      const payload = this.prepareData()
      this.$store
        .dispatch(CUPOM_DESCONTO_CREATE, payload)
        .then(result => {
          if (!result) {
            return this.$vueOnToast.pop(
              'success',
              'Erro ao salvar cupom de desconto'
            )
          }
          const msg = 'Cupom de desconto cadastrado com sucesso'
          this.$vueOnToast.pop('success', msg)
          this.$router.replace('/fornecedor/comercial/cupom-desconto')
          this.$emit('close')
        })
        .catch(e => {
          return this.$vueOnToast.pop('error', e.error)
        })
    },
    onClose() {
      this.$emit('close')
    },
    onBuscaClienteCancel() {
      this.buscaClientes.show = false
      this.buscaClientes.confirm = false
    },
    onBuscaClientesConfirm() {
      this.buscaClientes.show = false
      this.buscaClientes.confirm = true
    },
    onBuscaClienteLimpar() {
      this.buscaClientes = this.getCleanBuscaCliente()
    },
    onDeletedContent() {
      this.form.setor.valorMinPedido = null
      this.form.setor.valorMaxPedido = null
    },
    queryDate(start, date) {
      if (start && date) {
        return date + 'T00:00:00-03'
      } else if (!start && date) {
        return date + 'T23:59:59-03'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.block-multi-clientes {
  padding: 5px;
  display: flex;
  align-items: baseline;
  .check-box {
    flex: 1;
  }
}
</style>
