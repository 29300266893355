<template>
  <div>
    <div class="info-text">
      <small>
        Os filtros desta página serão utilizados para selecionar os clientes que
        utilizaram o cupom.
      </small>
    </div>
    <FormSelect
      label="Segmento do cliente"
      :options="segments"
      :clearable="true"
      v-model="buscaClientes.form.segmentos"
    />
    <LineDivider><h4>Data do cadastro</h4></LineDivider>
    <Grid>
      <FormDatePicker
        v-model="buscaClientes.form.dataCadastroInicio"
        label="Data inicial"
      />
      <FormDatePicker
        v-model="buscaClientes.form.dataCadastroFim"
        label="Data fim"
      />
    </Grid>
    <LineDivider><h4>Data da compra</h4></LineDivider>
    <Grid>
      <FormDatePicker
        v-model="buscaClientes.form.dataCompraInicio"
        label="Data inicial"
      />
      <FormDatePicker
        v-model="buscaClientes.form.dataCompraFim"
        label="Data fim"
      />
    </Grid>
    <LineDivider><h4>Data do último acesso</h4></LineDivider>
    <Grid>
      <FormDatePicker
        v-model="buscaClientes.form.dataAcessoInicio"
        label="Data inicial"
      />
      <FormDatePicker
        v-model="buscaClientes.form.dataAcessoFim"
        label="Data fim"
      />
    </Grid>
    <LineDivider><h4>Data de nascimento</h4></LineDivider>
    <Grid>
      <FormDatePicker
        v-model="buscaClientes.form.dataNascimentoInicio"
        label="Data inicial"
      />
      <FormDatePicker
        v-model="buscaClientes.form.dataNascimentoFim"
        label="Data fim"
      />
    </Grid>
    <Grid>
      <FormCheckbox
        label="Apenas carrinho em aberto"
        v-model="buscaClientes.form.carrinhoAberto"
      />
      <FormCheckbox
        label="Apenas cadastro aprovado"
        v-model="buscaClientes.form.cadastroAprovado"
      />
    </Grid>

    <FormButtonGroup align="end" spaced>
      <FormButton text="Voltar" type="default" @click="onCancelarBuscar" />
      <FormButton text="Confirmar" type="primario" @click="onBuscarClientes" />
    </FormButtonGroup>
  </div>
</template>

<script>
import FormDatePicker from '@/components/form/FormDatePicker'
import FormSelect from '@/components/form/FormSelect'
import FormCheckbox from '@/components/form/FormCheckbox'
import Grid from '@/components/Grid'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormButton from '@/components/form/FormButton'
import LineDivider from '@/components/LineDivider'
import { required } from 'vuelidate/lib/validators'
import mixinUtils from '@/mixins/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'CupomDescontoBuscaClientes',
  mixins: [mixinUtils],
  components: {
    Grid,
    FormSelect,
    FormCheckbox,
    FormDatePicker,
    FormButtonGroup,
    FormButton,
    LineDivider
  },
  validations: {
    form: {
      codigoCupom: {
        required,
        alphanumeric(cod) {
          return cod && cod.length !== cod.replace(/[^a-zA-Z0-9]/g, '').length
            ? false
            : true
        }
      },
      tipoCupom: { required },
      valorDesconto: {
        required,
        numeric(val) {
          return val && val.length !== val.replace(/[^0-9,]/g, '').length
            ? false
            : true
        }
      },
      dataInicio: { required },
      dataFim: { required }
    }
  },
  props: {
    segments: Array,
    buscaClientes: Object
  },
  created() {
    this.form = this.cleanForm
  },
  computed: {
    ...mapGetters(['getFornecedorId'])
  },
  methods: {
    getDataToSelect(item, labelField, valueField) {
      return item
        ? {
            text: item[labelField],
            value: item[valueField]
          }
        : null
    },
    onCancelarBuscar() {
      this.$emit('close')
    },
    onBuscarClientes() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.info-text {
  padding: 30px;
  text-align: center;
}
</style>
